import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/shahid/Desktop/Projects/Personal/madrasatulilm.com/src/views/layouts/MainLayout.js";
import { Helmet } from 'react-helmet';
import { Box, Breadcrumb, Divider, SEO, Text } from '../views/components';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO pageTitle="সুবিধাসমূহ" mdxType="SEO" />
    <Box maxWidth={960} margin="0 auto" padding={{
      xs: 3,
      sm: 4
    }} mdxType="Box">
      <Text variant="h2" textAlign="center" mdxType="Text">
  সুবিধাসমূহ
      </Text>
      <Divider mdxType="Divider" />
      <Breadcrumb links={[{
        url: '/',
        name: 'নীড়পাতা'
      }, {
        url: '/facilities/',
        name: 'সুবিধাসমূহ',
        isCurrentPage: true
      }]} mdxType="Breadcrumb" />
      <Divider mdxType="Divider" />
      <p>{`মাদ্রাসাতুল ইলম এ থেকে নিম্নোক্ত সুবিধাগুলো পেতে পারেন:`}</p>
      <ul>
        <li parentName="ul">{`বালক শাখার জন্য আবাসিক/অনাবাসিক সুবিধা;`}</li>
        <li parentName="ul">{`বালিকা শাখায় অনাবাসিক ব্যবস্থা, তবে মহিলা শিক্ষিকা দ্বারা পাঠদান;`}</li>
        <li parentName="ul">{`বালক-বালিকা উভয় শাখায় পাঠদানের জন্য নিরিবিলি পরিবেশ;`}</li>
        <li parentName="ul">{`আবাসিক ছাত্রদের জন্য উন্নত খাবারের ব্যবস্থা;`}</li>
        <li parentName="ul">{`শিক্ষার্থীদের খেলাধুলার জন্য নিরাপদ স্থান;`}</li>
        <li parentName="ul">{`সি-সি ক্যামেরা দ্বারা সার্বিক পরিস্থিত নজরদারীতে রাখা;`}</li>
        <li parentName="ul">{`আবাসিক ছাত্রদের দেখাশোনার জন্য একজন তত্ত্বাবধয়কের অবস্থান।`}</li>
      </ul>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      